<template>
    <div>
        <el-drawer
            title="操作记录"
            :size="500"
            :visible.sync="drawerVisible"
            ref="drawer"
            direction="rtl">
            <div class="content">
                <div class="content-layout a-flex-1">
                    <el-timeline>
                        <el-timeline-item
                            color="#B9B9B9"
                            v-for="(item, index) in tableData"
                            :key="index"
                            :timestamp="item.createTime">
                            <div>
                                <span class="block a-c-666" style="padding-bottom: 12px">{{ item.name }} {{ item.phone }}</span>
                                <span style="line-height: 20px">{{ item.content }}</span>
                            </div>
                        </el-timeline-item>
                    </el-timeline>
                    <div style="width: 100%;height:100%" class="a-flex-rcc" v-if="!tableData.length">
                        <el-empty description="暂无记录"></el-empty>
                    </div>
                </div>
                <div class="a-flex-rsbc">
                    <el-button class="a-flex-1" @click="$refs.drawer.closeDrawer()">关闭</el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    import util from '../../../utils/util.js'
    export default {
        data() {
            return {
                util: util,
                drawerVisible: false,
                tableData: [],
                recordInfo: ''
            };
        },
        created () {

        },
        methods:{
            getRecordList () {
                let paymentTypeDic = {
                    1: '预估',
                    2: '实际'
                }
                this.$Axios._post({
                    url: this.$Config.apiUrl.engineerOperateList,
                    params: {
                        id: this.recordInfo.id
                    },
                }).then(({data})=>{
                    let list = data.filter(item=>{
                        return item.afterJson != item.beforeJson
                    })
                    this.tableData = list.map(item=>{
                        let content = ''
                        try{
                            let oldRecord = JSON.parse(item.beforeJson)
                            let newRecord = JSON.parse(item.afterJson)
                            // content += `用户 ${newRecord.mobile} 的`
                            if(oldRecord.stationName != newRecord.stationName) { //站点修改
                                content += `站点从【${oldRecord.stationName}】修改为【${newRecord.stationName}】；`
                            }
                            if(oldRecord.companyName != newRecord.companyName) { //工程商户修改
                                content += `工程商户从【${oldRecord.companyName}】修改为【${newRecord.companyName}】；`
                            }
                            if(oldRecord.paymentType != newRecord.paymentType) { //工程款类型修改
                                content += `工程款类型从【${paymentTypeDic[oldRecord.paymentType]}】修改为【${paymentTypeDic[newRecord.paymentType]}】；`
                            }
                            if(oldRecord.payment != newRecord.payment) { //工程款修改
                                content += `工程款从【￥${(oldRecord.payment/100).toFixed(2)}】修改为【￥${(newRecord.payment/100).toFixed(2)}】；`
                            }
                            if(oldRecord.beforeRate != newRecord.beforeRate) { //工程款修改
                                content += `回收前分配比例从【${(oldRecord.beforeRate/100).toFixed(2)}】修改为【${(newRecord.beforeRate/100).toFixed(2)}】；`
                            }
                            if(oldRecord.afterRate != newRecord.afterRate) { //工程款修改
                                content += `回收后分配比例从【${(oldRecord.afterRate/100).toFixed(2)}】修改为【${(newRecord.afterRate/100).toFixed(2)}】；`
                            }
                        }catch(e){
                            //TODO handle the exception
                        }
                        return {
                            ...item,
                            name: '操作人：'+item.operateUserMobile,
                            createTime: item.operateTime,
                            content: content
                        }
                    })
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .content{
        padding: 0 24px 24px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        height: 100%;
        .content-layout{
            overflow-y: scroll;
            margin-bottom: 24px;
        }
        .content-layout::-webkit-scrollbar {
            /*width: 0;宽度为0隐藏*/
            width: 0 !important;
            height: 0 !important;
        }
        /deep/ .el-timeline{
            padding: 12px 0 0 6px !important;
        }
        /deep/ .el-timeline-item__tail{
            top: 16px !important;
            bottom: 4px !important;
            height: auto !important;
        }
        /deep/ .el-timeline-item{
            padding-bottom: 30px !important;
        }
        .dot{
            width: 15px;
            height: 15px;
            background: #B9B9B9;
            border-radius: 50%;
            border: 3px solid #ffffff;
        }
    }
</style>