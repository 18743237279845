<template>
    <div class="app-body taskPage">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">工程合同管理</span>
            <div class="a-flex-rcc">
                <!-- <el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfile">导出</el-button> -->
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="handleEdit('add')">添加分成</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch">
                <le-select-remote-search label="站点" v-model="pageParam.params.stationId" :options="stationFilterOptions" placeholder="请选择" />
                <le-select-remote-search label="项目" v-model="pageParam.params.incomeId" :options="incomeFilterOptions" placeholder="请选择" />
                <le-select-remote-search label="工程商户" v-model="pageParam.params.companyId" :options="companyFilterOptions" placeholder="请选择" />
                <le-select-local-search label="状态" v-model="pageParam.params.status" :options="statusDic" />
            </le-search-form>
            <le-pagview ref="engineeringListPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.engineeringList" :pageSizeDefault='10'>
                <el-table ref="engineeringList" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%" >
                    <el-table-column prop="id" label="分成编号" min-width="80">
                        <template slot-scope="{ row }">
                            <span>{{ row.id || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="taskName" label="站点名称" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.stationName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="cityName" label="项目名称" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.incomeName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="districtName" label="所属商户" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.stationCompanyName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="mobile" label="工程商户" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.companyName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceType" label="工程款" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.payment) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="reviewMobile" label="收款进度" min-width="140">
                        <template slot-scope="{ row }">
                            <div class="a-flex-cfsfs a-w-100" style="align-items: stretch">
                                <span>{{ (row.repayment / row.payment * 100).toFixed(2) }}%</span>
                                <div class="progress">
                                    <div :style="{ 'width': (Number((row.repayment / row.payment * 100).toFixed(2)) >= 100 ? 100 : Number((row.repayment / row.payment * 100).toFixed(2))) + '%' }"></div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="reviewTime" label="回收占比" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ util.numFormat(row.beforeRate) }}%</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="reviewStatus" label="回收后占比" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ util.numFormat(row.afterRate) }}%</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="reviewStatus" label="工程款类型" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.paymentType | initDic(paymentTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="reviewStatus" label="还款开始时间" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.repayStart || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="reviewStatus" label="还款结束时间" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.repayEnd || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="reviewStatus" label="状态" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.status | initDic(statusDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pid" label="操作" width="100" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="分成详情" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="handleEdit('info',scope.row)" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="编辑" placement="top">
                                <img src="../../assets/icon/option-edit.png" class="a-wh-16" @click="handleEdit('edit',scope.row)" />
                            </el-tooltip>
                            <!-- <el-tooltip class="item " effect="dark" content="解除绑定" placement="top">
                                <img src="../../assets/icon/unbind.png" class="a-wh-16" @click="handleUnbind(scope.row)" />
                            </el-tooltip> -->
                            <el-tooltip class="item a-ml-12" effect="dark" content="操作日志" placement="top">
                                <img src="../../assets/icon/option-log.png" class="a-wh-16" @click="handleShowLog(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
        <engineering-edit-log ref="opratelog"></engineering-edit-log>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    import engineeringEditLog from './child/engineering-edit-log.vue'
    export default {
        components: { engineeringEditLog },
        data () {
            return {
                util: util,
                tableData: [1],
                pageParam: {
                    url: this.$Config.apiUrl.engineerList,
                    method: "post",
                    params: {
                        companyId: '',
                        incomeId: '',
                        stationId: '',
                        status: ''
                    },
                    freshCtrl: 1,
                },
                companyFilterOptions: {
                    url: this.$Config.apiUrl.getUnderCompany,
                    method: "post",
                    params: {
                        companyType: 3,
                        isSignAgency: 1,
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                    pageSize: 100
                },
                incomeFilterOptions: {
                    url: this.$Config.apiUrl.incomeDistriList,
                    method: "post",
                    params: {
                        queryCompanyId: ''
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                    pageSize: 100
                },//项目数据
                stationFilterOptions: {
                    url: this.$Config.apiUrl.getStationInfoList,
                    method: "post",
                    params: {
                        incomeId: ''
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "searchKey",  //incomeDistriId
                    pageSize: 100
                },//站点数据
                statusDic: [],
                paymentTypeDic: [{
                    label: '预估',
                    value: 1
                },{
                    label: '实际',
                    value: 2
                }]
            }
        },
        created () {
            this.$getDic('constructionStatus','select').then(res=>{ this.statusDic = res; })
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods:{
            //获取列表
            setTableData(data) {
                this.tableData = data
            },
            handlerRest() {
                this.pageParam.params = {
                    companyId: '',
                    incomeId: '',
                    stationId: '',
                    status: ''
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['engineeringListPage'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            handleEdit (type,datas) {
                this.$router.push({
                    path: '/engineering/engineering-edit',
                    query:{
                        id: datas?datas.id:'',
                        type: type,
                    }
                })
            },
            handleShowLog (datas) {
                this.$refs['opratelog'].recordInfo = datas
                this.$refs['opratelog'].getRecordList()
                this.$refs['opratelog'].drawerVisible = true
            },
            exportfile () {
                // if(!this.pageParam.params.startTime) {
                //     this.$message.error('导出时请先选择提交时间')
                //     return
                // }
                // if(this.$getDay.enumerateDaysBetweenDates(this.pageParam.params.startTime,this.pageParam.params.endTime).length > 92){
                //     this.$message.error('最大导出范围3个月')
                //     return
                // }
                // let startTime = this.pageParam.params.startTime.split(' ')[0]
                // let endTime = this.pageParam.params.endTime.split(' ')[0]
                // this.$exfile({
                //     code: 20,
                //     fileName: startTime + ' 至 ' + endTime + '小区信息登记',
                //     startTime: this.pageParam.params.startTime,
                //     endTime: this.pageParam.params.endTime,
                //     params: this.pageParam.params
                // })
            }
        }
    }
</script>

<style lang="scss" scoped>
.taskPage{
    /deep/ .s-search-label{
        width: 100px !important
    }
}
.s-btn-add{
    width: 105px;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
.progress{
    width: 100%;
    height: 6px;
    min-height: 6px !important;
    border-radius: 3px;
    background: rgb(235, 238, 245);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &>div{
        height: 100%;
        min-height: 100% !important;
        background: #007af0;
        border-radius: 3px;
    }
}
</style>